import * as PubSub from "pubsub-js";
var HostCommunication = /** @class */ (function () {
    function HostCommunication(state) {
        var _this = this;
        this.eventQueueList = [];
        this.hostStoredEvent = "[HOST__PRIVATE] Ask For Stored Event";
        // public methods from PubSub
        this.publish = PubSub.publish;
        this.publishSync = PubSub.publishSync;
        this.subscribe = PubSub.subscribe;
        //@ts-ignore
        this.subscribeAll = PubSub.subscribeAll;
        this.subscribeOnce = PubSub.subscribeOnce;
        this.clearAllSubscriptions = PubSub.clearAllSubscriptions;
        //@ts-ignore
        this.clearSubscriptions = PubSub.clearSubscriptions;
        this.countSubscriptions = PubSub.countSubscriptions;
        this.getSubscriptions = PubSub.getSubscriptions;
        this.unsubscribe = PubSub.unsubscribe;
        // public self methods
        this.getEventQueueList = function () { return _this.eventQueueList; };
        this.addAddPrivateListeners = function () {
            // private listeners
            PubSub.subscribe(_this.hostStoredEvent, function (eventName, payload) {
                var storedEventFromQueList = _this.findEventInQueue(payload);
                // publish stored event
                if (storedEventFromQueList) {
                    _this.publish(storedEventFromQueList.eventName, storedEventFromQueList.eventPayload);
                }
            });
        };
        this.addPublicListeners = function () {
            _this.subscribeAll(function (eventName, eventPayload) {
                _this.removeEventFromQueue(eventName);
                _this.eventQueueList.push({
                    eventName: eventName,
                    eventPayload: eventPayload,
                });
            });
        };
        this.findEventInQueue = function (eventName) {
            return _this.eventQueueList.find(function (event) { return event.eventName === eventName; });
        };
        this.removeEventFromQueue = function (eventName) {
            var storedEventFromQueList = _this.findEventInQueue(eventName);
            var idx = _this.eventQueueList.indexOf(storedEventFromQueList);
            if (idx !== -1) {
                _this.eventQueueList.splice(idx, 1);
            }
        };
        if (state === HostCommunication.internalSingletonState) {
            this.addAddPrivateListeners();
            this.addPublicListeners();
        }
        else {
            throw new ReferenceError(HostCommunication.instantiationError);
        }
    }
    Object.defineProperty(HostCommunication, "Instance", {
        get: function () {
            return (this.instance ||
                (this.instance = new this(HostCommunication.internalSingletonState)));
        },
        enumerable: false,
        configurable: true
    });
    // use ping if the the mfe that has not yet been rendered but wants to query the events
    HostCommunication.prototype.ping = function (eventName) {
        PubSub.publish(this.hostStoredEvent, eventName);
    };
    HostCommunication.prototype.destroy = function () {
        PubSub.clearAllSubscriptions();
    };
    HostCommunication.internalSingletonState = "INTERNAL_SINGLETON";
    HostCommunication.instantiationError = "[HostCommunication] Instantiating this directly will result in subscription errors, use the singleton approach: HostCommunication.Instance";
    return HostCommunication;
}());
export { HostCommunication };
