import { registerApplication, start } from 'single-spa';
import { HostCommunication } from './host-communication';
import installPendoSnippet  from './sci-pendo-config';

function domElementGetter(id) {
  const elementId = id || 'root';
  return document.getElementById(elementId);
}

const hostCommunication = HostCommunication.Instance;

const defaultCustomProps = {
  // DOM Element where the MFE will be located
  domElement: domElementGetter('root'),
  base_href: '/',
  
  //Channel to real data sharing between MFE
  host_communication: hostCommunication
};

registerApplication({
  name: "@sci/sci",
  app: () => System.import("@sci/sci"),
  activeWhen: ["/"],
  customProps: defaultCustomProps,
});

start({
  urlRerouteOnly: true,
});

// To install pendo script
installPendoSnippet();